import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { font, color } from '../../layout/global'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Video from "../../assets/videos/Anelar/anelar-temperature.mp4"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const TemperatureCounterVideo = ({ minTemp, maxTemp, hours, introText, subText, info }) => {
  
  return (
  <Wrapper>
    <div id="temperatureVideo">
        <video autoPlay playsInline muted loop>
          <source src={Video} type="video/mp4"></source>
        </video>
    </div>
    <div id="temperatureText">
      <h2 dangerouslySetInnerHTML={{__html: info.textM}}></h2>
      <p className="subtext">{info.subtext}</p>
    </div>
    
  </Wrapper>
  )
}

export default TemperatureCounterVideo

TemperatureCounterVideo.defaultProps = {
  introText: 'Recomeçar já não tem de ser do zero.',
  subText: 'Passadas 12 horas após desligado, perde apenas 50ºC.'
}
TemperatureCounterVideo.propTypes = {
  introText: PropTypes.string,
  subText: PropTypes.string
}

const Wrapper = styled.section`
  position: relative;
  background-color: black;
  width: 100%;
  min-height:100vh;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #temperatureText {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 60px 20px;
    text-align: center;
  }

  #temperatureText h2 {
    ${font.robotoBold};
    color: ${color.white};
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    font-size: 3.5rem;

    span{
      color: ${color.red};
    }
  }

  #temperatureText .subtext {
    ${font.roboto400};
    font-size: 6rem;
    color: ${color.white};
    font-size: 1.7rem;
  }

  #temperatureVideo{
    margin:0 auto;
  }
  
  #temperatureVideo video{
    width: 100%;
    max-width:450px;
  }
`