import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { font, ease, color, device } from '../../layout/global'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import LeftArrow from '../../images/Products/left-arrow.svg'
import RightArrow from '../../images/Products/right-arrow.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureCarousel = ({ _title, text, string, image, id, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  /* const [isMoving, setIsMoving] = useState(false) */
  const triggerRef = useRef(null), titleRef = useRef(null), lineRef = useRef(null), textRef = useRef(null), imageRef = useRef(null), /* pageNumRef = useRef(null), */ pagRef = useRef(null),
        /* prevSlide = useRef(null), currSlide = useRef(null), nextSlide = useRef(null), */ 
        prevArrowRef = useRef(null), nextArrowRef = useRef(null)
  const transitionTime = 300 // milliseconds

  useEffect(() => {
    let slides = document.querySelectorAll(`#${id} .slide`)
    slides.forEach((e, i) => {
      e.id = `slide_cp_${i + 1}`
    })
  }, []) // eslint-disable-line
  
  const Prev = () => {
    setCurrentSlide(currentSlide - 1)
  }
  const Next = () => {
    setCurrentSlide(currentSlide + 1)
  }
  const updateCurrentSlide = (index) => {
    currentSlide !== index && setCurrentSlide(index)
  }

  useEffect(() => {
    let selectedSlide = document.querySelectorAll(`#${id} .slide.selected`)
    let slides = document.querySelectorAll(`#${id} .slide:not(.selected)`)

    
    slides.forEach(e => e.style.opacity = 0)
    setTimeout(() => selectedSlide.forEach(e => e.style.opacity = 1), 850)
  }, [currentSlide]) // eslint-disable-line

  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia(device.tabletP).matches) {
      gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: triggerRef.current,
          start: '35% center'
        }
      })
      .fromTo(imageRef.current, {opacity: 0}, {opacity:1, duration: 0.3})
      .fromTo([ titleRef.current, lineRef.current, textRef.current, pagRef.current ], {opacity: 0, y:15}, {opacity: 1, y:0, stagger: 0.15, duration: 0.3})
    }
  },[])

  return (
    <Wrapper id={id} transitionTime={transitionTime} ref={triggerRef}>
      <div className="feat-image" ref={imageRef}>
      {image &&
        <img 
            src={image} 
            alt='feature-carousel' 
            style={{
                objectFit:'contain', 
                objectPosition:'center center'
            }}
        />
      }
      </div>
      <div className='container'>
        <h2 ref={titleRef} dangerouslySetInnerHTML={{ __html: _title}}></h2>
        <span className='text-line' ref={lineRef}></span>
        <p className="c-text" ref={textRef} dangerouslySetInnerHTML={{ __html: text}}></p>
        <div className="pagination" ref={pagRef}>
          <button id="left-arrow" onClick={Prev} ref={prevArrowRef} aria-label="Previous Item"><LeftArrow/></button>
          <button id="right-arrow" onClick={Next} ref={nextArrowRef} aria-label="Next Item"><RightArrow/></button>
        </div>
        <Container
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          transitionTime={1000}
          transition={transitionTime}
          interval={4500}
          className={className}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          swipeable={true}
          stopOnHover={true}
        >
          {string.map((i, index) =>
            <p key={index} className="slider-text">{i}</p>
          )}
        </Container>
      </div>
    </Wrapper>
  )
}

export default FeatureCarousel

const Wrapper = styled.section`
  display: grid;
  position: relative;
  background-color: #000;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  justify-content: center;
  justify-items: center;
  align-content: center;

  .feat-image{
    width:100vw;
    opacity: 1;
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
  .container h2 {
    width: 800px;
    margin: 0 auto;
    text-align: left;
    position: relative;
    ${font.robotoBold};
    font-size: 6rem;
    color: ${color.white};
    line-height: 1;
    letter-spacing: 1px;
    opacity: 1;
    & span {
      color: ${color.red};
    }
  }

  .text-line {
    display: block;
    width: 60px;
    height: 5px;
    background-color: ${color.white};
    margin-top: 50px;
  }

  .container .c-text {
    width: 800px;
    margin: 70px auto 0 auto;
    text-align: left;
    ${font.roboto400};
    font-size: 2rem;
    color: ${color.white};
    opacity: 1;
  }

  #page-num {
    transition: all 0.15s ${ease.out};
    width: 50vw;
    opacity: 0;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    ${font.robotoRegular};
    color: ${color.white};
    font-size: 3rem;
    letter-spacing: -1px;
    z-index: 1;
    transform: 150px !important;
    display: none;
  }
  #curr-page {
    font-size: 5rem;
  }
  #last-page {
    font-size: 3rem;
  }
  button {
    border: 0;
    background: none;
    margin-top: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    outline: 0;
    cursor: pointer;
    &:hover svg {
      fill: ${color.red};
    }
  }
  button svg {
    height: 100%;
    fill: white;
    transition: all 0.15s ${ease.out}; 
  }
  #left-arrow {
    margin-right: 10px;
  }
  #right-arrow {
    margin-left: 10px;
  }

  @keyframes arrowMoveLeft {
    0% { transform: translateX(0px) }
    50% { transform: translateX(-5px) }
    100% { transform: translateX(0px) }
  }
  @keyframes arrowMoveRight {
    0% { transform: translateX(0px) }
    50% { transform: translateX(5px) }
    100% { transform: translateX(0px) }
  }

  @media ${device.tabletP} and (max-width: 1920px) {
    .container h2 {
      width: calc(500px + 300 * (100vw - 768px)/(1920 - 768));
      font-size: calc(4rem + 20 * (100vw - 768px)/(1920 - 768));
    }
    .container .c-text {
      width: calc(500px + 300 * (100vw - 768px)/(1920 - 768));
      font-size: calc(1.8rem + 2 * (100vw - 768px)/(1920 - 768));
    }
    .container {
      width: calc(500px + 300 * (100vw - 768px)/(1920 - 768));
    }

    .pagination {
      height: calc(15px + 10 * (100vw - 768px)/(1920 - 768));
      font-size: calc(1.5rem + 15 * (100vw - 768px)/(1920 - 768));
      letter-spacing: calc(0 - 1 * (100vw - 768px)/(1920 - 768));
      transform: translateY(calc(50px + 150 * (100vw - 768px)/(1920 - 768))) !important;
    }
    #curr-page {
      font-size: calc(2rem + 30 * (100vw - 768px)/(1920 - 768));
    }
    #last-page {
      font-size: calc(1.5rem + 15 * (100vw - 768px)/(1920 - 768));
    }
    #left-arrow {
      margin-right: calc(0px + 10 * (100vw - 768px)/(1920 - 768));
    }
    #right-arrow {
      margin-left: calc(0px + 10 * (100vw - 768px)/(1920 - 768));
    }
  }
`

const Container = styled(Carousel)`
  transform: 150px !important;

  .carousel .slide {
    transition: all 0.15s ${ease.out};
    background: none;
    transform: translateY(25px);
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .container,
  .image > .gatsby-image-wrapper {
    height: 100%;
  }
  .container {
    position: relative;
  }
  .slider-text {
    text-align: center;
    width: 800px;
    height: 100px;
    margin: 0 auto;
    ${font.roboto400};
    font-size: 2rem;
    color: ${color.white};
    z-index: 1;
    transition: ${props => `all ${props.transition + 300}ms ${ease.out}`};
  }

  @media ${device.tabletP} and (max-width: 1920px) {
    transform: translateY(calc(1px + 150 * (100vw - 768px)/(1920 - 768)));

    .slider-text {
      font-size: calc(1.8rem + 2 * (100vw - 768px)/(1920 - 768));
      width: calc(480px + 320 * (100vw - 768px)/(1920 - 768));
    }
  }
`