import React, { useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { font, color, device } from '../../layout/global'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Video from "../../assets/videos/Anelar/anelar-temperature.mp4"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const TemperatureCounterVideo = ({ minTemp, maxTemp, hours, introText, subText, info }) => {
  const triggerRef = useRef(null),
        containerRef = useRef(null),
        container2Ref = useRef(null),
        textContainerRef = useRef(null),
        videoRef = useRef(null),
        tempRef = useRef(null),
        hoursRef = useRef(null),
        hoursStringRef = useRef(null),
        subTitleRef = useRef(null)

  var temperature = { var: maxTemp }
  var time = { var: 1 }

  useEffect(() => {
    let rTemp = document.querySelector('#temp-ref')
    let rHours = document.querySelector('#hours-ref')
    let rHoursString = document.querySelector('#hoursstring-ref')

    if (typeof window !== 'undefined' && window.matchMedia(device.tabletP).matches){
      // Timeline de entrada
      let tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: triggerRef.current,
          id: 'first',
          toggleActions: 'play none none none',
          start: '50% bottom'
        }
      })
      .addLabel('FadeInText', 0)
      .addLabel('FadeOutText', 1)
      .fromTo(containerRef.current, { opacity: 0, y: 15, }, { opacity: 1, y: 0, duration: 0.5 }, 'FadeInText')
      .to(containerRef.current, { opacity: 0, y: -15, duration: 0.5 }, 'FadeOutText')
      .fromTo(container2Ref.current, { opacity: 0 }, { opacity: 1, duration: 0.75 })
      .fromTo(textContainerRef.current, { opacity: 0, y: 5 }, { opacity: 1, y: 0, duration: 0.75 }, '<')
      .to(container2Ref.current, { duration: 1, onStart: () => {(videoRef.current !== null) && videoRef.current.play()}}, '>')
      tl.to(temperature, { var: minTemp, duration: 2, onUpdate: () => {rTemp.innerHTML = (temperature.var).toFixed()}, ease: 'Power1.easeInOut'}, '<+0.75' )
      tl.to(time, { var: hours, duration: 2, onUpdate: () => {rHours.innerHTML = (time.var).toFixed(); ((time.var) >= 2) && (rHoursString.innerHTML = info.hours)}, ease: 'Power2.easeInOut'}, '<')
      tl.fromTo(subTitleRef.current, { opacity: 0, y: 5 }, { opacity: 1, y: 0, duration: 0.5}, '>-1.5')
    }
  }, [hours, time, minTemp, temperature, info.hours])
  
  return (
  <Wrapper ref={triggerRef}>
    <div ref={containerRef} id="temperatureText">
      <h2 dangerouslySetInnerHTML={{__html:info.text}}></h2>
    </div>
    <div ref={container2Ref} id="temperatureVideo">
      <div className="container">
        <video ref={videoRef} playsInline muted>
          <source src={Video} type="video/mp4"></source>
        </video>
        <div className="text-container" ref={textContainerRef}>
          <p className="text"><span id="temp-ref" ref={tempRef}>{maxTemp}</span>ºC</p>
          <p className="text"><span id="hours-ref" ref={hoursRef}>1</span> <span id="hoursstring-ref" ref={hoursStringRef}>{info.hour}</span></p>
        </div>
        <p className="subtext" ref={subTitleRef}>{info.subtext}</p>
      </div>
    </div>
  </Wrapper>
  )
}

export default TemperatureCounterVideo

TemperatureCounterVideo.defaultProps = {
  minTemp: '240',
  maxTemp: '180',
  hours: 60,
  introText: 'Recomeçar já não tem de ser do zero.',
  subText: 'Passadas 12 horas após desligado, perde apenas 50ºC.'
}
TemperatureCounterVideo.propTypes = {
  minTemp: PropTypes.string,
  maxTemp: PropTypes.string,
  hours: PropTypes.number,
  introText: PropTypes.string,
  subText: PropTypes.string
}

const Wrapper = styled.section`
  position: relative;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 1;
  overflow: hidden;

  #temperatureText {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #temperatureText h2 {
    ${font.robotoBold};
    font-size: 8rem;
    color: ${color.white};
    letter-spacing: normal;
    line-height: normal;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  #temperatureText span {
    color: ${color.red};
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
  #temperatureVideo {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  #temperatureVideo .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #temperatureVideo video {
    width: 100%;
    transform: translateY(-15%);
  }

  #temperatureVideo .text-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 85%;
  }
  #temperatureVideo .text {
    color: white;
    ${font.robotoBold};
    font-size: 6rem;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
  #temperatureVideo .subtext {
    color: white;
    ${font.roboto400};
    font-size: 6rem;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    color: ${color.greyDarker};
    font-size: 3rem;
  }
`