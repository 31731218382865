import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import CarouselMobile from "../../components/Carousel/mobile"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
import FeatureCarousel from "../../components/FeatureCarousel"
// import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import TemperatureCounterWithVideo from "../../components/TemperatureCounterWithVideo"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import TemperatureCounterWithVideoMobile from "../../components/TemperatureCounterWithVideo/mobile"
import Configurador from "../../components/Configurador/"

// ASSETS
import AnelarVideo from "../../assets/videos/Anelar/anelar.mp4"
import AnelarVideoPreview from "../../assets/videos/Anelar/anelar-preview.mp4"
import AnelarDetailVideo from "../../assets/videos/Anelar/anelar-destaque.mp4"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const IndexPage = ({ location }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  var config = true
  if(activeLanguage === 'br' || activeLanguage === 'mx' ){
    config = false
  }
  return (
    <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
      <SEO
        pathname={location.pathname}
        title={t("meta", { returnObjects: true })["title"]}
        keywords={t("meta", { returnObjects: true })["keywords"]}
        description={t("meta", { returnObjects: true })["description"]}
        img={t("meta", { returnObjects: true })["image"]}
        imageHeight="628"
        imageWidth="1200"
        lang={React.useContext(I18nextContext).language}
        domain={location?.hostname}
      />
      
      <Video
        className="full-width full-height scrollable"
        srcp={AnelarVideo}
        src={AnelarVideoPreview}
        poster="https://ramalhos.com/video_posters/Anelar/anelar.jpg"
        width="100%"
        id="video-anelar"
        imgMobile={t("video", { returnObjects: true })["bgMobile"]}
        fullString={t("videoHome", { returnObjects: true })["info"]}
        toClick="#introduction"
      />
      
      <Introduction
        className="scrollable"
        id="introduction"
        translation={t("introduction", { returnObjects: true })}
        lang={activeLanguage}
        link={"../../config-anelar"}
        configurador
      />

      {/* IF MOBILE */}
      {breakpoints.tl ? (
        <CarouselMobile
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-anelar-mobile"
          carouselContent={t("carousel", { returnObjects: true })}
        />
      ) : (
        //IF NOT
        <Carousel
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-anelar"
          carouselContent={t("carousel", { returnObjects: true })}
        />
      )}

      {/* DESKTOP */}

      {!breakpoints.tl && (
        <>
          <FeatureLine
            id="first-line-anelar"
            _title={t("feature", { returnObjects: true })["title1"]}
            text={t("feature", { returnObjects: true })["text1"]}
            image={t("feature", { returnObjects: true })["image1_1"]}
            titleWidth="800px"
            textWidth="550px"
            midBrightness
          />
          
          <VideoDetail
            src={AnelarDetailVideo}
            poster="https://ramalhos.com/video_posters/Anelar/anelar-destaque.jpg"
            id="video-detail-anelar"
            _title={t("video", { returnObjects: true })["title1"]}
            text1={t("video", { returnObjects: true })["text1_1"]}
            text2={t("video", { returnObjects: true })["text1_2"]}
          />
          
          <TemperatureCounterWithVideo info={t("temp", { returnObjects: true })} />
          
          <FeatureCarousel
            _title={t("feature", { returnObjects: true })["title2"]}
            text={t("feature", { returnObjects: true })["text2"]}
            image={t("feature", { returnObjects: true })["image2_1"]}
            string={t("lcd", { returnObjects: true })["string"]}
            id="feature-line-anelar2"
          />
          
          <FeatureLine
            id="feature-line-anelar3"
            _title={t("feature", { returnObjects: true })["title3"]}
            text={t("feature", { returnObjects: true })["text3"]}
            image={t("feature", { returnObjects: true })["image3_1"]}
            titleWidth="800px"
            textWidth="500px"
          />
        </>
      )}

      {/* MOBILE */}

      {breakpoints.tl && (
        <>
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t("feature", { returnObjects: true })["title1"]}
            text1={t("feature", { returnObjects: true })["text1"]}
            image={t("feature", { returnObjects: true })["image1_1M"]}
            positionCenter
          />
          
          <FeatureLineMobile
            id="third-line-mobile"
            _title={t("video", { returnObjects: true })["title1"]}
            text1={t("video", { returnObjects: true })["text1_1"]}
            text2={t("video", { returnObjects: true })["text1_2"]}
            image={t("feature", { returnObjects: true })["image2_1M"]}
            positionTopBottom
          />
          
          <TemperatureCounterWithVideoMobile info={t("temp", { returnObjects: true })} />
          
          <FeatureLineMobile
            id="fourth-line-mobile"
            _title={t("feature", { returnObjects: true })["title2"]}
            text1={t("feature", { returnObjects: true })["text1"]}
            image={t("feature", { returnObjects: true })["image3_1M"]}
            positionTopBottom
          />
        </>
      )}

      {config && <Configurador id="anelar-initial" content={t("configurador", { returnObjects: true })}/>}

      {/* <SpecsTable
        id="specs-table"
        specsTable={t("specs", { returnObjects: true })["content"]}
        specsLength={t("specs", { returnObjects: true })["content"].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="280px"
      /> */}
      
      {!(activeLanguage === "br") && (
        <FormContact id="form-contact" info={t("form", { returnObjects: true })} lang={activeLanguage} location={location} />
      )}
    </Layout>
  )
}

export default IndexPage

export const QueryAnelarPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home", "anelar", "form", "footer", "header"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
